export {
  faArrowUpFromBracket,
  faArrowUpRightFromSquare,
  faBackward,
  faBan,
  faBars,
  faCalendarAlt,
  faCheck,
  faCheckCircle,
  faChevronRight,
  faCircleArrowDown,
  faCircleArrowUp,
  faCircleInfo,
  faCirclePlus,
  faCircleXmark,
  faCloudUpload,
  faCopy,
  faDownload,
  faEllipsisVertical,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faFileAlt,
  faFileCircleCheck,
  faFileExcel,
  faFileInvoice,
  faFileInvoiceDollar,
  faFilePdf,
  faFilter,
  faFilterCircleXmark,
  faGear,
  faHourglassHalf,
  faKey,
  faLock,
  faMoneyBillTransfer,
  faPaperPlane,
  faPause,
  faPen,
  faPencil,
  faPencilAlt,
  faPerson,
  faPlay,
  faPlus,
  faReplyAll,
  faRotateRight,
  faStopwatch,
  faStore,
  faSuitcase,
  faTimesCircle,
  faTrash,
  faTriangleExclamation,
  faUnlock,
  faUser,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
